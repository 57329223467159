import { AxiosError } from 'axios';
import { TChatPromptState } from 'libs/models/ChatPrompt.model';
import { TKDDState } from 'libs/models/KDD.model';
import {
  CreateNewRequestResponse,
  RequestDetailsResponse,
  RequestResponse,
  TBooleanReducer,
  TFetchAllRequestsPayload,
  TFetchDataRequestPayload,
  TProjectType,
  TSaveBtnFlags,
  TSaveEditsResponse,
  TSectionDetails,
} from 'libs/models/landingPageModels';
import { TProcessMaturityTable } from 'libs/models/ProcessMaturity.model';
import { TProcessSimplificationState } from 'libs/models/ProcessSimplification.model';
import {
  ModifyHeaderResponse,
  RegeneratePayload,
  RequestPayload,
  SaveApiPayload,
  SaveHeadersPayload,
} from 'libs/models/ReviewScreenModels';
import { TArchitectureState } from './Architecture.state';
import { TProjectPlan } from './ProjectPlan.state';
import { TReviewScreenState } from './ReviewScreen.state';
import { TDownloadFileResponse, TWorkshopEnablersTableCols } from './WorkshopEnablers.state';
export const FETCH_DATA_REQUEST_REVIEW = 'FETCH_DATA_REQUEST_REVIEW';
export const FETCH_DATA_SUCCESS_REVIEW = 'FETCH_DATA_SUCCESS_REVIEW';
export const FETCH_DATA_FAILURE_REVIEW = 'FETCH_DATA_FAILURE_REVIEW';

export interface DataState {
  data: any;
  loading: boolean;
  error: any | null;
}

export type TDataState<T> = {
  data: T | null;
  loading: boolean;
  error: any | null;
};

export type TRootState = {
  allRequests: TDataState<TTableResponse<RequestDetailsResponse>>;
  workshopEnablers: TDataState<TSubSectionResponse<TWorkshopEnablersTableCols>[]> & {
    fileData: TDataState<TDownloadFileResponse>;
  };
  projectPlan: TProjectPlan;
  allSections: TDataState<TSectionDetails[]>;
  createFormProjectTypes: TDataState<TProjectType[]>;
  kdd: TKDDState;
  processMaturity: TDataState<TSubSectionResponse<TProcessMaturityTable>[]>;
  reviewScreen: TDataState<TReviewScreenState>;
  executiveSummary: TDataState<TSubSectionResponse<null>[]>;
  saveBtnFlagReducer: TSaveBtnFlags;
  processSimplification: TProcessSimplificationState;
  fetchDownloadGeneratedFile: DataState;
  booleanReducer: TBooleanReducer;
  architecture: TArchitectureState;
  chatPrompt: TChatPromptState;
  fetchRequestId: any;
  editedContentToSave: TDataState<TSaveEditsResponse>;
  sectionsById: TDataState<TSectionDetails[]>;
  regenerateHeader: TDataState<ModifyHeaderResponse>;
};

export type TTableResponse<T> = {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  filters: Record<string, string[]>;
  colNames: string[];
  content: T[];
};

export type TSectionPayload = {
  reqId: string;
  selectedSection: string;
  pageSize: number;
  subsection?: string;
};

export type TSubSectionPayload = {
  section: string;
  subsection: string;
  pageSize: number;
  page: number;
  selectedFilters: Record<string, string[]>;
  search?: string;
};

export type TSectionResponse<T> = {
  response: TSubSectionResponse<T>[];
};

export type TSubSectionDataResponse<T> = {
  response: string;
  filtered?: boolean;
  highlights: string;
  table: TTableResponse<T> | null;
};

export type TSubSectionResponse<T> = {
  processArea: string;
  subsection: string;
  data: TSubSectionDataResponse<T>;
};

export type TFetchSubSectionPayload = {
  requestId: string;
  body: TSubSectionPayload;
};

export interface LandingDataState {
  data: any;
  loading: boolean;
  error: any | null;
}

export type TSaveFlagAction = {
  type: 'SET_SAVE_FLAG';
  payload: {
    section: string;
    value: boolean;
  };
};
// store subsection value

export type TSetSubsectionDataAction = {
  type: 'SET_SUBSECTION_VALUE';
  payload: {
    subsection: string;
  };
};

// getTable details
export interface FetchDataRequestAction {
  type: 'FETCH_ALL_REQUESTS';
  payload: TFetchAllRequestsPayload;
}

export interface FetchDataSuccessAction {
  type: 'FETCH_REQUESTS_SUCCESS';
  payload: TTableResponse<RequestDetailsResponse>;
}

export interface FetchDataFailureAction {
  type: 'FETCH_REQUESTS_FAILURE';
  payload: any;
}

// Create new request
export interface CreateRequestRequestAction {
  type: 'CREATE_REQUEST_REQUEST';
}

export interface CreateRequestSuccessAction {
  type: 'CREATE_REQUEST_SUCCESS';
  payload: CreateNewRequestResponse;
}

export interface CreateRequestFailureAction {
  type: 'CREATE_REQUEST_FAILURE';
  payload: any;
}

// Upload file
export interface UploadFileRequestAction {
  type: 'UPLOAD_NEW_FILE_REQUEST';
}

export interface UploadFileSuccessAction {
  type: 'UPLOAD_NEW_FILE_SUCCESS';
  payload: CreateNewRequestResponse;
}

export interface UploadFileFailureAction {
  type: 'UPLOAD_NEW_FILE_FAILURE';
  payload: any;
}

export interface UploadFileReset {
  type: 'RESET_UPLOAD_NEW_FILE';
}

// generate Process slide content.
export interface ProcessRequestGenerateSlideContentRequestAction {
  type: 'PROCESS_GENERATE_SLIDE_CONTENT_REQUEST';
  payload: {
    requestId: number;
    filePrefixes: string;
    operation: string;
    files: File[];
    sections: string[];
  };
}

export interface ProcessRequestGenerateSlideContentSuccessAction {
  type: 'PROCESS_GENERATE_SLIDE_CONTENT_SUCCESS';
  payload: any;
}

export interface ProcessRequestGenerateSlideContentFailureAction {
  type: 'PROCESS_GENERATE_SLIDE_CONTENT_FAILURE';
  payload: any;
}

// fetch request by ID

export interface RequestByIdState {
  data: any;
  loading: boolean;
  error: any | null;
}
export interface TFetchRequestById {
  type: 'GET_REQUEST_BY_ID_REQUEST';
  payload: {
    requestId: number;
  };
}

export interface TFetchRequestByIdSuccess {
  type: 'GET_REQUEST_BY_ID_SUCCESS';
  payload: RequestResponse;
}

export interface TFetchRequestByIdFailure {
  type: 'GET_REQUEST_BY_ID_FAILURE';
  payload: any;
}
export interface ResetRequestById {
  type: 'RESET_REQUEST_BY_ID';
}

export const ALL_SECTION_DETAILS_REQUEST = 'ALL_SECTION_DETAILS_REQUEST';
export const ALL_SECTION_DETAILS_SUCCESS = 'ALL_SECTION_DETAILS_SUCCESS';
export const ALL_SECTION_DETAILS_FAILURE = 'ALL_SECTION_DETAILS_FAILURE';

export interface TAllSectionDetailsRequest {
  type: 'ALL_SECTION_DETAILS_REQUEST';
  payload: TAllSectionDetailsPayload;
}

export interface TAllSectionDetailsSuccess {
  type: 'ALL_SECTION_DETAILS_SUCCESS';
  payload: TSectionDetails[];
}

export interface TAllSectionDetailsFailure {
  type: 'ALL_SECTION_DETAILS_FAILURE';
  payload: AxiosError;
}

export type TAllSectionDetailsPayload = {
  projectTypeId: number;
};

// fetch Project Types data
export type TFetchProjectTypesRequest = {
  type: 'GET_PROJECT_TYPE_REQUEST';
};

export interface TFetchProjectTypesSuccess {
  type: 'GET_PROJECT_TYPE_SUCCESS';
  payload: TProjectType[];
}

export interface TFetchProjectTypesFailure {
  type: 'GET_PROJECT_TYPE_FAILURE';
  payload: any;
}

export interface RequestByIdState {
  data: any;
  loading: boolean;
  error: any | null;
}

export interface FetchDataRequestActionReview {
  type: 'FETCH_DATA_REQUEST_REVIEW';
  payload: TFetchDataRequestPayload;
}

export interface FetchDataSuccessActionReview {
  type: 'FETCH_DATA_SUCCESS_REVIEW';
  payload: any;
}

export interface FetchDataFailureActionReview {
  type: 'FETCH_DATA_FAILURE_REVIEW';
  payload: any;
}

export interface ResetDataReviewAction {
  type: 'RESET_DATA_REVIEW';
}

export interface ResetSidenavDataState {
  type: 'RESET_SIDENAV_DATA';
}

// save editor content

export interface PutEditedContentToSaveState {
  data: any;
  loading: boolean;
  error: any | null;
}

export interface PutEditedContentToSaveRequestAction {
  type: 'PUT_EDITED_CONTENT_TO_SAVE_REQUEST';
  payload: { reqId: number; selectedItem: string; payload: SaveApiPayload[] };
}

export interface PutEditedContentToSaveSuccessAction {
  type: 'PUT_EDITED_CONTENT_TO_SAVE_SUCCESS';
  payload: any;
}

export interface PutEditedContentToSaveFailureAction {
  type: 'PUT_EDITED_CONTENT_TO_SAVE_FAILURE';
  payload: any;
}

// download generated files
export interface FetchDownloadGeneratedFileRequest {
  type: 'FETCH_DOWNLOAD_REQUEST';
}

export interface FetchDownloadGeneratedFileSuccess {
  type: 'FETCH_DOWNLOAD_SUCCESS';
  payload: any;
}

export interface FetchDownloadGeneratedFileFailure {
  type: 'FETCH_DOWNLOAD_FAILURE';
  payload: any;
}

// Chat API
export interface ChatAPIRequest {
  type: 'CHAT_PROMPT_REQUEST';
}

export interface ChatAPISuccess {
  type: 'CHAT_PROMPT_SUCCESS';
  payload: any;
}

export interface ChatAPIFailure {
  type: 'CHAT_PROMPT_FAILURE';
  payload: any;
}

export interface ChatAPIReset {
  type: 'CHAT_PROMPT_RESET';
}

//put process API
export interface PutProcessRequestGenerateSlideContentRequestAction {
  type: 'PUT_PROCESS_GENERATE_SLIDE_CONTENT_REQUEST';
  payload: RequestPayload;
}

export interface PutProcessRequestGenerateSlideContentSuccessAction {
  type: 'PUT_PROCESS_GENERATE_SLIDE_CONTENT_SUCCESS';
  payload: any;
}

export interface PutProcessRequestGenerateSlideContentFailureAction {
  type: 'PUT_PROCESS_GENERATE_SLIDE_CONTENT_FAILURE';
  payload: any;
}

//Regenerate Process API
export interface RegenerateHeaderRequestAction {
  type: 'MODIFY_HEADER_REQUEST';
  payload: RegeneratePayload;
}

export interface RegenerateHeaderSuccessAction {
  type: 'MODIFY_HEADER_SUCCESS';
  payload: CreateNewRequestResponse;
}

export interface RegenerateHeaderFailureAction {
  type: 'MODIFY_HEADER_FAILURE';
  payload: any;
}

export interface RegenerateHeaderResetAction {
  type: 'RESET_HEADER_RESPONSE';
}

//Save regenerate
export interface SaveRegenerateHeaderRequestAction {
  type: 'SAVE_REGENERATE_HEADER_REQUEST';
  payload: SaveHeadersPayload;
}

export interface SaveRegenerateHeaderSuccessAction {
  type: 'SAVE_REGENERATE_HEADER_SUCCESS';
  payload: CreateNewRequestResponse;
}

export interface SaveRegenerateHeaderFailureAction {
  type: 'SAVE_REGENERATE_HEADER_FAILURE';
  payload: any;
}

export type TFetchRequestByIdActionTypes =
  | TFetchRequestById
  | TFetchRequestByIdSuccess
  | TFetchRequestByIdFailure;
export type TSectionDetailsActionTypes =
  | TAllSectionDetailsRequest
  | TAllSectionDetailsSuccess
  | TAllSectionDetailsFailure;

export type DataActionTypes =
  | TSectionDetailsActionTypes
  | FetchDataRequestAction
  | FetchDataSuccessAction
  | FetchDataFailureAction
  | CreateRequestRequestAction
  | CreateRequestSuccessAction
  | CreateRequestFailureAction
  | UploadFileRequestAction
  | UploadFileSuccessAction
  | UploadFileFailureAction
  | UploadFileReset
  | TFetchRequestByIdActionTypes
  | ResetRequestById
  | ProcessRequestGenerateSlideContentRequestAction
  | ProcessRequestGenerateSlideContentSuccessAction
  | ProcessRequestGenerateSlideContentFailureAction
  | FetchDataRequestActionReview
  | FetchDataSuccessActionReview
  | FetchDataFailureActionReview
  | ResetDataReviewAction
  | ResetSidenavDataState
  | PutEditedContentToSaveRequestAction
  | PutEditedContentToSaveSuccessAction
  | PutEditedContentToSaveFailureAction
  | FetchDownloadGeneratedFileFailure
  | FetchDownloadGeneratedFileRequest
  | FetchDownloadGeneratedFileSuccess
  | ChatAPIRequest
  | ChatAPISuccess
  | ChatAPIFailure
  | ChatAPIReset
  | PutProcessRequestGenerateSlideContentRequestAction
  | PutProcessRequestGenerateSlideContentSuccessAction
  | PutProcessRequestGenerateSlideContentFailureAction
  | RegenerateHeaderRequestAction
  | RegenerateHeaderFailureAction
  | RegenerateHeaderSuccessAction
  | RegenerateHeaderResetAction
  | SaveRegenerateHeaderRequestAction
  | SaveRegenerateHeaderSuccessAction
  | SaveRegenerateHeaderFailureAction
  | TFetchProjectTypesRequest
  | TFetchProjectTypesSuccess
  | TFetchProjectTypesFailure;
