import axios, { AxiosResponse } from 'axios';
import { TProjectType, RequestResponse, UploadFilePayload, TFetchAllRequestsPayload, RequestDetailsResponse, TSectionDetails } from '../../models/landingPageModels';
import { TAllSectionDetailsPayload, TTableResponse } from '../../store/DmapPhase0State/LandingPageState';
import JSZip from "jszip";
import { ROUTES } from '../../../constants';

// API call to get the Request table details
export const fetchRequestDetailsData = async (payload: TFetchAllRequestsPayload): Promise<TTableResponse<RequestDetailsResponse>> => {
  try {
    const { pageNumber, pageSize } = payload
    const response: AxiosResponse<TTableResponse<RequestDetailsResponse>> = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/phase-zero/request/processRequests${pageSize || pageNumber ? '?' : ''}${pageSize ? 'pageSize='+pageSize : ''}${pageNumber ? (pageSize ? '&' : '')+'pageNumber='+pageNumber : ''}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}; 

// Call for  creating requestId
export const createNewRequestAPI = async (requestName: string, projectTypeId: number, sections: string, uploadPayload:UploadFilePayload, operation:string ) => {
  try {
    const createResponse = await axios
      .post<
        {
          requestName: string;
          projectTypeId: number;
          sections: string;
        },
        AxiosResponse<{
          requestId: string;
          message: string;
        }>
      >(
        `${process.env.REACT_APP_BASE_URL}/api/phase-zero/request/create`,
        { requestName, projectTypeId, sections },
        {
          headers: {
            responseType: 'json',
          },
        },
      )
      .then((response) => {
        return response.data;
      });
    return createResponse;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// call for the generate content after submitting the files.
export const processRequestGenerateSlideContent = async (
  requestId: number,
  filePrefix: string,
  operation: string,
  files: Record<string, any>,
  sections: string[]
) => { 
  try {
    const zip = new JSZip();
    Object.entries(files).forEach((file, index) => {
      zip.file(file[0], file[1])
    });
    zip.generateAsync({ type: "blob" }).then(async function (content) {
      let processReuestResponse;
      const formData = new FormData();
      formData.append("files", content, "zippedfile.zip");
      processReuestResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/phase-zero/request/process?request_id=${requestId}&operation=${operation}&prefixes=${filePrefix}&sections=${sections}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return processReuestResponse.data;
    });
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// call to fetch the status by request ID
export const fetchRequestById = async (requestId: number,) => {
  try {
    const response = await axios.get<any, AxiosResponse<RequestResponse>>(
      `${process.env.REACT_APP_BASE_URL}/api/phase-zero/request/process/${requestId}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// call for fetch all section details
export const fetchAllSectionDetails = async (payload: TAllSectionDetailsPayload) => {
  try {
    const response: AxiosResponse<TSectionDetails[]> = await axios.get(ROUTES.GET_ALL_SECTION_DETAILS(payload));
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// call to get the projectType data
export const fetchProjectTypes = async () => {
  try {
    const response = await axios.get<AxiosResponse<TProjectType[]>>(ROUTES.GET_PROJECT_TYPES());
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
